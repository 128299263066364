import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { Container, Row } from 'reactstrap';
import BaseLayout from '../layouts/BaseLayout';
import IntroBlock from '../components/IntroBlock/IntroBlock';
import LanguageContext from '../context/Language';

const Suppliers = ({ data }) => {
  const context = useContext(LanguageContext);
  const localData = data[context.lang];

  return (
    <BaseLayout title={localData.title} description={localData.seoDescription}>
      <IntroBlock data={localData} />
    </BaseLayout>
  );
};

export default Suppliers;

export const query = graphql`
  query SuppliersQuery {
    site {
      siteMetadata {
        env
      }
    }
    en: contentfulContactPage(
      contentful_id: { eq: "1tSQ2NVZ2gfbC4THAZA6le" }
      node_locale: { eq: "en-GB" }
    ) {
      id
      title
      headerImage {
        ...HeaderImageFragment
      }
      introduction {
        ...IntroBlockFragment
      }
      seoDescription
    }
    nl: contentfulContactPage(
      contentful_id: { eq: "1tSQ2NVZ2gfbC4THAZA6le" }
      node_locale: { eq: "nl-NL" }
    ) {
      id
      title
      headerImage {
        ...HeaderImageFragment
      }
      introduction {
        ...IntroBlockFragment
      }
      seoDescription
    }
  }
`;
